export default async (to, from, next) => {
  var token = localStorage.getItem("currencyToken") || '';

  // console.log(to);
  const isUAT = window.location.href.includes('uat');

    if (token.length > 0) {
      // if (userInfo.password2 == null) {
      //   next('/web/set-sec-password');
      // }
      next();
    } else {
      localStorage.removeItem("currencyToken");
      // next('/web/sessions/login');
      if (isUAT) {
        window.location.href = "https://uat.jaboclub.com";
      } else {
        window.location.href = "https://jaboclub.com";
      }
    }
  
};
